@import "bs4/functions";
@import "bs4/variables";
@import "bs4/mixins";


@import "global-modules/custom-mixin";
@import "global-modules/placeholder";



.home-carousel{
  border-top: 1px solid darken(theme-color("primary"), 5%);
  border-bottom: 1px solid $gray-600;

  .carousel-item{
    height: 280px;
  }

  .carousel-image{
    background-size: cover;
    height: 100%;
  }

  .c-caption {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center; 
    justify-content: center; 
    width: 100%;
    text-align: center;
    z-index: 10;
    //background: linear-gradient(to right, rgba(#e0eafc,.1), rgba(#cfdef3,.2)); 

    &__text{
      @include rem(font-size, 15px);
      color: #fff;
      border-radius: 4px;
      border: 1px solid rgba(#a5d6a7,.4);
      @include rem(padding, 15px 25px);
      background-color: rgba(#a5d6a7,.3);
    
      a{
        color: inherit;
      }
    }
  }

  @include media-breakpoint-up(md) {

    .c-caption {
      &__text{
        @include rem(font-size, 30px);
        @include rem(padding, 19px 40px);
      }
    }

    .carousel-item{
      //height: 480px;
      height: 380px;
    }
  }
}

.carousel-indicators {
  li {
    background-color: rgba($link-color, .5);
  }
  .active {
    background-color: $link-color;
  }
}


.carousel-control-prev,
.carousel-control-next{
  z-index: 15;
}

.carousel-control-prev-icon,
.carousel-control-next-icon{

  .icon-angle-left,
  .icon-angle-right{
    @include svg-size(9px, 18px);
  }
}

@include media-breakpoint-up(md) {
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon{

    .icon-angle-left,
    .icon-angle-right{
      @include svg-size(9px, 24px);
    }
  }
}



